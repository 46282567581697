/* requires:
polyfill.js
*/

// display the current year in the footer
if (document.querySelector('.copyright-year')) {
  document.querySelector('.copyright-year').innerHTML = new Date().getFullYear();
}

// this is a cross-domain cookie that triggers the fis cart
cart.setCookie('useCart', 'fis');
